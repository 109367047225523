import "./App.css";
import { useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

// MUI
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setLang } from "./slices/userLang";
import { setIsAuth, setRefresh } from "./slices/userAuth";
import { setUserType } from "./slices/userTypeSlice.js";
import { createGlobalStyle } from "styled-components";

// Always import small components that are used globally
import ProtectedRoute from "./components/ProtectedRoute";
import NotificationSnackbar from "./components/NotificationSnackbar";
import useAnalytics from "./hooks/useAnalytics.jsx";

// Import theme which is small and needed immediately
import { theme } from "./themes.js";

// Lazy load all route components
const LandingPage = lazy(() => import("./routes/LandingPage/LandingPage"));
const Login = lazy(() => import("./routes/Authentication/Login"));
const Signup = lazy(() => import("./routes/Authentication/Signup"));
const UnauthorizedPage = lazy(
  () => import("./routes/Authentication/UnauthorizedPage")
);
const AccountVerification = lazy(
  () => import("./routes/Authentication/AccountVerification")
);
const ProfileSetup = lazy(() => import("./routes/ProfileSetup/ProfileSetup"));
const Intro = lazy(() => import("./routes/ProfileSetup/Intro"));
const Overview = lazy(() => import("./routes/Overview/Overview"));
const OverviewPreview = lazy(() => import("./routes/Overview/OverviewPreview"));
const ResetPassword = lazy(
  () => import("./routes/ResetPassword/ResetPassword")
);
const ForgotPassword = lazy(
  () => import("./routes/ForgotPassword/ForgotPassword")
);
const ConfirmUpload = lazy(() => import("./routes/ModifyUser/ConfirmUpload"));
const ModifyUser = lazy(() => import("./routes/ModifyUser/ModifyUser"));
const NfcVerification = lazy(() => import("./routes/Nfc/NfcVerification"));
const NfcNavigator = lazy(() => import("./routes/Nfc/NfcNavigator"));
const LinkNfc = lazy(() => import("./routes/LinkNfc/LinkNfc"));
const ShareOptions = lazy(() => import("./routes/Options/ShareOptions"));
const SettingsOptions = lazy(() => import("./routes/Options/SettingsOptions"));
const ContactInfo = lazy(() => import("./routes/Links/ContactInfo"));
const WebSiteInfo = lazy(() => import("./routes/Links/WebSiteInfo"));
const SocialInfo = lazy(() => import("./routes/Links/SocialInfo"));
const PdfInfo = lazy(() => import("./routes/Links/PdfInfo"));
const VideoInfo = lazy(() => import("./routes/Links/VideoInfo"));
const ReviewInfo = lazy(() => import("./routes/Links/ReviewInfo"));
const ModifyAppearance = lazy(
  () => import("./routes/ModifyAppearance/ModifyAppearance")
);
const Appearance = lazy(() => import("./routes/Appearance/Appearance"));

// Create a simplified loading component to reduce bundle size
const LoadingSpinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <div
      style={{
        width: "40px",
        height: "40px",
        border: "4px solid #f3f3f3",
        borderTop: "4px solid #3498db",
        borderRadius: "50%",
        animation: "spin 1s linear infinite",
      }}
    ></div>
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

// Lazy load the motion library for animations
const AnimationWrapper = lazy(() => import("./components/AnimationWrapper"));

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ffffff;
  }
`;

// Create a separate component for the animated wrapper
function SimplifiedWrapper({ children }) {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AnimationWrapper>{children}</AnimationWrapper>
    </Suspense>
  );
}

function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <LandingPage />
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <SimplifiedWrapper>
            <Login />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/signup"
        element={
          <SimplifiedWrapper>
            <Signup />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/signup/:nfc_code"
        element={
          <SimplifiedWrapper>
            <Signup />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/profile-setup/:user_id"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ProfileSetup />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/intro"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <Intro />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/nfc/link/:user_id"
        element={
          <SimplifiedWrapper>
            <LinkNfc />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/nfc/verification/:nfc_code/"
        element={
          <SimplifiedWrapper>
            <NfcVerification />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/account-verification/:otp_id"
        element={
          <SimplifiedWrapper>
            <AccountVerification />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <SimplifiedWrapper>
            <ResetPassword />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <SimplifiedWrapper>
            <ForgotPassword />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/overview"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <Overview />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-user/:user_id/upload-image"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ConfirmUpload />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/appearance/:user_id"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <Appearance />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-user/:user_id"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ModifyUser />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-appearance/:user_id"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ModifyAppearance />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/contact/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ContactInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/website/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <WebSiteInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/social/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <SocialInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/pdf/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <PdfInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/video/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <VideoInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-link/review/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ReviewInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/contact/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ContactInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/pdf/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <PdfInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/website/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <WebSiteInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/social/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <SocialInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/video/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <VideoInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/modify-link/review/:idFromUrl"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ReviewInfo />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/overview/share"
        element={
          <ProtectedRoute requiredType="NU">
            <SimplifiedWrapper>
              <ShareOptions />
            </SimplifiedWrapper>
          </ProtectedRoute>
        }
      />
      <Route
        path="/overview/settings"
        element={
          <SimplifiedWrapper>
            <SettingsOptions />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/unauthorized"
        element={
          <SimplifiedWrapper>
            <UnauthorizedPage />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/:username"
        element={
          <SimplifiedWrapper>
            <OverviewPreview />
          </SimplifiedWrapper>
        }
      />
      <Route
        path="/nfc/:code"
        element={
          <SimplifiedWrapper>
            <NfcNavigator />
          </SimplifiedWrapper>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Init application language
    if (!localStorage.getItem("language")) {
      const userLang = navigator.language || navigator.userLanguage;

      if (userLang !== "it-IT" && userLang !== "en-US") {
        dispatch(
          setLang({
            language: "en-US",
          })
        );
      } else {
        dispatch(
          setLang({
            language: userLang,
          })
        );
      }
    } else {
      dispatch(
        setLang({
          language: localStorage.getItem("language"),
        })
      );
    }
  }, [dispatch]);

  // Init isAuth & refresh
  const accessToken = localStorage.getItem("access");
  const refreshToken = localStorage.getItem("refresh");
  const userType = JSON.parse(localStorage.getItem("user_type"));
  useEffect(() => {
    if (accessToken) {
      dispatch(setIsAuth());
    }

    if (refreshToken) {
      dispatch(setRefresh());
    }

    if (userType) {
      dispatch(setUserType({ userType }));
    }
    // eslint-disable-next-line
  }, [accessToken, refreshToken, userType]);

  const applicationLanguage = useSelector((state) => state.userLang.value);

  useAnalytics();

  return (
    <>
      <GlobalStyle />
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<LoadingSpinner />}>
            <RoutesWithAnimation applicationLanguage={applicationLanguage} />
            <NotificationSnackbar />
          </Suspense>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
