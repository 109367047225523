import React from "react";
import * as Sentry from "@sentry/react";

export const initializeSentry = () => {
  const environment = process.env.NODE_ENV;

  // Initialize Sentry only in production or staging
  if (environment === "production" || environment === "staging") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
        }),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        /^\//, // Matches internal routes
        /^https:\/\/hellolink\.pro\//, // Matches production API or frontend
      ],
      environment: environment,
      replaysSessionSampleRate: 0.1, // Capture 10% of sessions
      replaysOnErrorSampleRate: 1.0, // Capture all sessions with errors
    });
  } else {
    console.info(
      `Sentry is not initialized in the '${environment}' environment.`
    );
  }
};
