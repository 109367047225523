import { useEffect } from "react";
import { BASE_URL_ENDPOINT } from "../api/common.js"; // Adjust the path as needed

/**
 * Custom hook to handle lazy loading of analytics tools
 *
 * @param {Object} options - Configuration options
 * @param {number} options.delay - Delay in ms before loading analytics (default: 1000)
 * @param {boolean} options.debug - Whether to log debug information (default: false)
 * @param {string} options.gaTrackingId - Google Analytics tracking ID
 * @param {number} options.hotjarSiteId - Hotjar site ID
 * @param {number} options.hotjarVersion - Hotjar version
 * @returns {void}
 */
const useAnalytics = ({
  delay = 1000,
  debug = true,
  gaTrackingId = "G-744YRGECEQ",
  hotjarSiteId = 3907563,
  hotjarVersion = 6,
  devEndpoint = "http://localhost:8080/api/V0.0.0/",
} = {}) => {
  // Logger function that only logs when debug is true
  const log = (message, data) => {
    if (debug) {
      if (data) {
        console.log(`[Analytics]: ${message}`, data);
      } else {
        console.log(`[Analytics]: ${message}`);
      }
    }
  };

  useEffect(() => {
    log("App rendering completed - Effect executed");

    // Only load analytics in production environment
    const isProduction = BASE_URL_ENDPOINT !== devEndpoint;

    if (isProduction) {
      log("Production environment detected - Preparing analytics");

      const loadHotjar = async () => {
        log("Starting Hotjar loading...");
        const startTime = performance.now();

        try {
          const Hotjar = await import("@hotjar/browser");
          log("Hotjar imported, structure:", Object.keys(Hotjar));

          Hotjar.default.init(hotjarSiteId, hotjarVersion);

          log(
            `Hotjar initialized in ${(performance.now() - startTime).toFixed(2)}ms`
          );
        } catch (error) {
          console.error("[Analytics Error]: Failed to load Hotjar:", error);
        }
      };

      const loadGA = async () => {
        log("Starting Google Analytics loading...");
        const startTime = performance.now();

        try {
          const ReactGA = await import("react-ga4");
          log("ReactGA imported, structure:", Object.keys(ReactGA));

          ReactGA.default.initialize(gaTrackingId);

          log(
            `Google Analytics initialized in ${(performance.now() - startTime).toFixed(2)}ms`
          );
        } catch (error) {
          console.error(
            "[Analytics Error]: Failed to load Google Analytics:",
            error
          );
        }
      };

      log(`Setting ${delay}ms timeout for analytics loading...`);
      const timer = setTimeout(() => {
        log("Timeout completed - Starting analytics loading");
        loadHotjar();
        loadGA();
      }, delay);

      return () => {
        log("Cleanup: timer cancelled");
        clearTimeout(timer);
      };
    } else {
      log("Development environment - Analytics not loaded");
    }
  }, [debug, delay, gaTrackingId, hotjarSiteId, hotjarVersion, devEndpoint]);
};

export default useAnalytics;
